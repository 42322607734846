<template>
  <div class="dashboard">
    <div class="main">
      <div class="above-table">
        <div style="display: flex;">
          <Magnifier class="search-icn"></Magnifier>
          <input class="search" placeholder="Search assessments" v-model="searchQuery" type="search"/>
           <Multiselect
          class="multiselect rowCount single"
          v-model="rowCountDropValue"
          :options="rowOptions"
          track-by="label"
          label="label"
          :showLabels="false"
          :allow-empty="false"
          :searchable="false"
        >{{filterValue}}</Multiselect>
        </div>
          <div class="float-right">
             <div>
          <button @click="goToCreate()">Create Assessment</button>
        </div>
        </div>
      </div>
      <div class="table-container" style='margin-bottom: 40px;'>
        <data-table
        :headings="assessmentsTableHeaders"
        :rows="assessments"
        :perPage="rowCountDropValue.value"
        :page="currentPage"
        :search-query="searchQuery"
        >
        <template v-slot:actionsSlot="slotProps">
          <div v-if="slotProps.row.status === '1' && !slotProps.row.expired" class="edit-gray"
          v-tooltip="{
              content: 'In order to edit an assessment, the status must be set to Inactive.',
              }">Edit</div>
          <div v-if="slotProps.row.status === '0' || slotProps.row.expired" class='edit-blue'  @click.stop="editRow(slotProps.row)">Edit</div>
          <span style="margin: 0 10px; border-right: 1px solid #B6BFC5"></span>
          <div style="color: #00B0F0; cursor: pointer;" @click.stop="duplicateRow(slotProps.row)">Duplicate</div>
          <span style="margin: 0 10px; border-right: 1px solid #B6BFC5"></span>
          <div v-if="slotProps.row.status === '0' || slotProps.row.expired" style="color: #FF335C; cursor: pointer;" @click.stop="deleteRow(slotProps.row)">Delete</div>
              <div v-if="slotProps.row.status === '1' && !slotProps.row.expired" class="edit-gray" @click.stop="deleteRow(slotProps.row)"
          v-tooltip="{
              content: 'In order to delete an assessment, the status be set to Inactive.',
              }">Delete</div>
          </template>
         <template v-slot:statusSlot="slotProps">
            <toggle-button
            v-if="!slotProps.row.expired"
            style="margin-right: 10px;"
            color= "#00B0F0"
            :value="slotProps.row.status === '1' ? true : false"
            @change="toggleStatus(slotProps.row)"
             v-tooltip="{
              content: slotProps.row.status === '0' ? 'This assessment is currently set to Inactive.': 'This assessment is currently set to Live.',
              }"></toggle-button>
            <span style="font-size: 14px;" v-if="slotProps.row.expired">Expired</span>
            <span style="font-size: 14px;" v-else-if="slotProps.row.status === '1'">Live</span>
            <span style="font-size: 14px;" v-else-if="slotProps.row.status === '0'">Inactive</span>
          </template></data-table>
          <div class="row-container" v-if="assessments.length > 0">
            <BasePagination
              :current-page="currentPage"
              :page-count="pageCount"
              class="articles-list__pagination"
              @nextPage="pageChangeHandle('next')"
              @previousPage="pageChangeHandle('previous')"
              @loadPage="pageChangeHandle"
              />
          </div>
      </div>
    </div>
    <modal class="modal-screens" v-show="isModalOpen">
      <template v-slot: header>
        <div>{{modalHeader}}</div>
      </template>
      <template v-slot:body class="body">
        <div class="app-modal">
          <h3>Are you sure you would like to delete this assessment?</h3>
        </div>
      </template>
      <template v-slot:footer>
        <button @click="commit" style="margin-right: 13px;">YES</button>
        <button @click="closeModal">NO</button>
      </template>
    </modal>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import moment from 'moment';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import DataTable from '@/components/DataTable.vue';
import Magnifier from '@/assets/icons/magnifier.svg';
import Modal from '@/components/Modal.vue';
import BasePagination from '@/components/BasePagination.vue';

export default {
  name: 'assessments',
  components: {
    DataTable,
    Magnifier,
    Multiselect,
    Modal,
    BasePagination,
  },
  data() {
    return {
      assessments: [],
      currentPage: 1,
      toggleColor: '#00B0F0',
      searchQuery: '',
      isModalOpen: false,
      modalHeader: 'CONFIRM',
      rowOptions: this.$store.state.rowOptions,
      selectedRow: null,
      filterOptions: [
        {
          label: 'Completions',
          value: 'completions',
        },
        {
          label: 'Status',
          value: 'status',
        },
        {
          label: 'Service',
          value: 'sales',
        },
        {
          label: 'Other',
          value: 'sales',
        },
      ],
      filterValue: '',
      rowCountDropValue: { label: 'Show 25', value: 25 },
    };
  },
  methods: {
    editRow(row) {
      if (row.status !== '1' || row.expired) {
        this.$store.commit('SET_SELECTED_ASSESSMENT', row);
        this.$router.push({
          name: 'edit',
        });
      }
    },
    deleteRow(row) {
      if (row.status !== '1') {
        this.selectedRow = row;
        this.isModalOpen = true;
      }
    },
    commit() {
      const loader = this.$loading.show();
      this.$store.dispatch('deleteAssessment', { assessmentID: this.selectedRow.id })
        .then(() => {
          this.$notify({
            text: 'Assessment Deleted Successfully.',
            group: 'primary',
            type: 'success',
          });
          this.$store.commit('SET_ALL_ASSESSMENT_DATA', []);
          this.getAssessments();
          this.isModalOpen = false;
          loader.hide();
        });
    },
    closeModal() {
      this.isModalOpen = false;
    },
    duplicateRow(row) {
      const newRow = { ...row };
      newRow.name += ' duplicate';
      this.$store.commit('SET_SELECTED_ASSESSMENT', newRow);
      this.$router.push({
        name: 'duplicate',
      });
    },
    getAssessments() {
      let assessmentData = [];
      const loader = this.$loading.show();
      if (this.$store.state.assessments.length === 0) {
        this.$store.dispatch('getAssessments', { assessmentID: '' })
          .then(() => {
            if (this.$store.state.assessments.length > 0) {
              assessmentData = this.$store.state.assessments;
              for (let i = 0; i < assessmentData.length; i += 1) {
                if (this.beforeToday(assessmentData[i].deadline_date)) {
                  assessmentData[i].expired = true;
                } else {
                  assessmentData[i].expired = false;
                }
              }
            }
            this.assessments = assessmentData;
            this.forceRefresh();
            loader.hide();
          });
      } else {
        assessmentData = this.$store.state.assessments;
        for (let i = 0; i < assessmentData.length; i += 1) {
          if (this.beforeToday(assessmentData[i].deadline_date)) {
            assessmentData[i].expired = true;
          } else {
            assessmentData[i].expired = false;
          }
        }
        this.assessments = assessmentData;
        this.forceRefresh();
        loader.hide();
      }
    },
    toggleStatus(row) {
      const loader = this.$loading.show();
      const newRow = JSON.parse(JSON.stringify(row));
      if (row.status === '0') {
        // eslint-disable-next-line no-param-reassign
        row.status = '1';
        newRow.status = '1';
        newRow.deadline_date = this.$moment.utc(row.deadline_date).format('MM/DD/YY');
      } else {
        // eslint-disable-next-line no-param-reassign
        row.status = '0';
        newRow.status = '0';
        newRow.deadline_date = this.$moment.utc(row.deadline_date).format('MM/DD/YY');
      }
      this.$store.dispatch('updateAssessment', { assessmentID: row.id, params: newRow })
        .then(() => {
          this.$notify({
            text: 'Status Updated Successfully.',
            group: 'primary',
            type: 'success',
          });
          loader.hide();
        });
    },
    goToCreate() {
      this.$router.push({
        name: 'create',
      });
    },
    beforeToday(dateString) {
      const REFERENCE = moment(new Date());
      const TODAY = REFERENCE.clone().startOf('day');

      function isBeforeToday(momentDate) {
        return momentDate.isBefore(TODAY);
      }
      const date = new Date(dateString);
      if (date) {
        if (isBeforeToday(moment(date))) {
          return true;
        }

        return false;
      }
      return dateString;
    },
    async pageChangeHandle(value) {
      switch (value) {
        case 'next':
          this.currentPage += 1;
          break;
        case 'previous':
          this.currentPage -= 1;
          break;
        default:
          this.currentPage = value;
      }
    },
    forceRefresh() {
      this.$forceUpdate();
    },
  },
  computed: {
    assessmentsTableHeaders() {
      return this.$store.state.assessmentsTableHeaders;
    },
    pageCount() {
      const l = this.assessments.length;
      const s = this.rowCountDropValue.value;
      return Math.ceil(l / s);
    },
  },
  watch: {
    searchQuery(val) {
      if (val) {
        this.currentPage = 1;
      }
    },
    pageCount(val) {
      if (val === 1) {
        this.currentPage = 1;
      }
    },
  },
  created() {
    this.getAssessments();
  },
};
</script>

<style lang="scss" scoped>

.search-icn {
  right: -15px;
}

.search {
  margin-left: -21px;
}

.header-middle {
  display: flex;
}

.table-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-bottom: 25px;
}

.table-container {
  font-size: 26px;
  color: black;
}

.above-table {
  display: flex;
  justify-content: space-between;
  color: black;
  margin-bottom: 25px;
}

.edit-blue {
color: #00B0F0;
cursor: pointer;
}

.edit-gray {
color: #B6BFC5;
cursor: default;
}

.float-right {
  display: flex;
}
</style>
